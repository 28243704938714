import { ReactElement } from 'react';

function TermOfSevice(): ReactElement {
  return (
    <>
      <h1 className="my-4 text-4xl font-bold">달러 캐시 서비스 이용 약관</h1>
      <p className="p1">[서비스 이용약관]</p>
      <p className="p2">
        <br />
      </p>
      <p className="p1">제 1조 [목적]</p>
      <p className="p1">
        본 약관은 회원이 머니모아(이하 &quot;회사&quot;)가 제공하는 달러 캐시
        서비스 (이하 &quot;서비스&quot;)를 이용함에 있어 회원과 회사 간의 권리,
        의무 및 책임사항, 서비스 이용에 따른 이용조건 및 절차 등 기본적인 사항을
        규정함을 목적으로 합니다.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p1">제 2조 [정의]</p>
      <p className="p1">
        본 약관에서 사용하는 용어의 정의는 다음 각 호와 같습니다.
      </p>
      <p className="p1">
        ① 서비스란, 단말기(PC, 스마트폰 등 각종 유무선 장치 포함)와 상관없이
        회원 또는 이용자가 이용할 수 있도록 회사가 제공하는 제반 서비스를
        의미합니다.
      </p>
      <p className="p1">
        ② 회원이란, 회사의 서비스를 이용하기 위해 이용약관에 동의 및 필요한
        정보를 등록하고 회사가 제공하는 서비스를 이용하는 자를 말합니다.
      </p>
      <p className="p1">
        ③ 이용자란, 회사의 서비스에 접속하여 서비스를 이용하는 회원 및 회원가입
        없이 서비스를 이용하는 자를 통칭하여 말합니다.
      </p>
      <p className="p1">
        ④ 서명이란, 회원이 본인임을 확인하고 보호하기 위해 회원 자신이 등록한
        숫자 또는 패턴 또는 지문 등을 의미합니다.
      </p>
      <p className="p1">
        ⑤ 포인트란, 회사가 인정하는 방법을 통하여 추천, 출석, 미션, 뉴스보기
        등을 통해 받은 지급 수단을 말합니다.
      </p>
      <p className="p1">
        ⑥ 상품이란 포인트을 사용하여 획득 한 실물로 전달되는 배송 상품을 말하며,
        모바일교환권의 종류는 다음 각 항목과 같습니다.
      </p>
      <p className="p1">
        가. 물품교환형은 특정 상품 등과 교환할 수 있는 권리가 기록된
        모바일교환권을 말합니다.
      </p>
      <p className="p1">
        나. 금액 형은 비 정액 또는 정액 형 선불전자지급수단으로 유효기간 내에
        잔액 범위 내에서 사용횟수에 제한 없이 자유롭게 상품 등으로 교환할 수
        있는 권리가 기록된 모바일교환권을 말합니다.
      </p>
      <p className="p1">
        ⑦ 본 약관에 사용되는 용어 중 본 조에서 정하지 않은 부분은 관계 법령 및
        일반관례에 따릅니다.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p1">제 3조 [약관 등의 명시와 설명 및 개정]</p>
      <p className="p1">
        ① 회사는 본 약관의 내용을 회원이 보기 쉽도록 서비스의 화면 내 또는
        별도의 연결화면에 게시하거나 팝업화면 등으로 제공합니다.
      </p>
      <p className="p1">
        ② 회사는 &quot;전자상거래등에서의소비자보호에관한법률&quot;,
        &quot;약관의규제에관한법률&quot;,
        &quot;정보통신망이용촉진및정보보호등에관한법률&quot;,
        &quot;소비자기본법&quot; 등 관계법을 위배하지 않는 범위에서 본 약관을 개
        정할 수 있습니다.
      </p>
      <p className="p1">
        ③ 회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여
        현행약관과 함께 서비스 내의 적절한 장소에 개정약관의 적용일 자 30일
        전부터 공지합니다. 다만, 법령의 개정 등의 사유로 긴급하게 약관을 변경할
        때에는 변경된 약관을 1개월 이상 게시하고 즉시 공지합니다. 이 경우 회사는
        개정 전 내용과 개정 후 내용을 명확히 비교하여 회원이 알기 쉽도록
        표시합니다.
      </p>
      <p className="p1">
        ④ 회사가 제3항에 따라 개정약관을 공지 또는 통지하는 경우, 회원에게
        &quot;약관 변경 적용일까지 거부의사를 표시하지 아니할 경우 약 관 변경에
        동의한 것으로 본다.&quot;라는 취지의 내용을 함께 고지합니다.
      </p>
      <p className="p1">
        ⑤ 회원이 약관의 변경 내용에 대해 이의를 제기하지 아니하는 경우에는
        약관의 변경을 승인한 것으로 봅니다.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p1">제 4조 [약관의 해석]</p>
      <p className="p1">
        ① 회사는 포인트의 충전, 출금 서비스 등 전자금융거래에 대해서는 별도의
        약관(이하 &quot;전자금융거래이용약관&quot;)을 둘 수 있습니다.
      </p>
      <p className="p1">
        ② 전자금융거래와 관련된 사항은 전자금융거래이용약관을 우선 적용합니다.
      </p>
      <p className="p1">제5조 [회원가입 및 이용계약 체결]</p>
      <p className="p1">
        ① 이용자는 회사가 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에
        동의한다는 의사표시를 함으로써 회원가입을 신청하고, 회사가 이러한 신청을
        승낙 함으로써 회원가입 및 이용계약체결이 완료됩니다.
      </p>
      <p className="p1">
        ② 회사는 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각 호에
        해당하지 않는 한 회원으로 승낙합니다.
      </p>
      <p className="p1">
        1. 이전에 회원자격을 상실한 적이 있는 경우. 다만, 재 가입 조건이
        충족되어 회사의 회원 재 가입 승낙을 얻은 경우에는 예외로 합니다.
      </p>
      <p className="p1">2. 등록 내용에 허위, 기재누락, 오기가 있는 경우</p>
      <p className="p1">3. 실명이 아니거나 타인의 명의를 이용한 경우</p>
      <p className="p1">4. 회원가입 신청 일 현재 만 14세 미만의 아동인 경우</p>
      <p className="p1">
        ③ 회사는 가입신청자의 실명을 확인하기 위해 전문기관을 통한 실명확인 및
        본인인증을 요청할 수 있습니다. 회사는 실명확인절차를 취할 수 없는
        가입신청에 대해서는 이용자에게 증빙자료를 요청할 수 있습니다.
      </p>
      <p className="p1">
        ④ 회원가입 계약의 성립 시기는 회사의 승낙이 회원에게 도달한 시점으로
        합니다.
      </p>
      <p className="p1">
        ⑤ 회원은 가입 시 회사에 제공한 정보에 변경이 있는 경우 즉시 고객센터,
        전자우편 등 기타 방법으로 회사에 대하여 그 변경 사항을 알려야 합니다.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p1">제 6조 [회원 탈퇴 및 자격 상실]</p>
      <p className="p1">
        ① 회원은 회사에 언제든지 탈퇴를 요청할 수 있으며 회사는 즉시 회원탈퇴를
        처리합니다. 단, 회원이 포인트 보유 시 달러 캐시 앱을 통해 포인트를 환급
        후 탈퇴할 수 있습니다.
      </p>
      <p className="p1">
        ② 회원이 다음 각 호의 사유에 해당하는 경우 회사는 회원자격을 제한 및
        정지시킬 수 있습니다.
      </p>
      <p className="p1">1. 가입 신청 시에 허위 내용을 기재한 경우</p>
      <p className="p1">2. 허위나 조작 된 방법으로 포인트를 획득 한 경우</p>
      <p className="p1">
        3. 다른 사람의 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를
        위협하는 경우
      </p>
      <p className="p1">4. 제 20조 [회원의 금지의무]를 위반하는 경우</p>
      <p className="p1">
        ③ 회사가 회원 자격을 제한, 정지시킨 후 동일한 행위가 2회 이상
      </p>
      <p className="p1">
        반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 회사는
        회원자격을 상실시킬 수 있습니다.
      </p>
      <p className="p1">
        ④ 회사가 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우
        회원에게 이를 통지하고, 회원등록 말소 전에 최소한 30일 이상의 기간을
        정하여 소명할 기회를 부여합니다.
      </p>
      <p className="p1">
        ⑤ 제1항에 의거 본인의 의지로 탈퇴한 회원의 경우 탈퇴일로부터 30일
        이전까지 회원에 다시 가입 할 수 있습니다. 단, 회사가 제공 하는 서비스의
        형태에 따라 재 가입이 불가능할 수도 있습니다.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p1">제 7조 [서비스 제공 및 변경]</p>
      <p className="p1">① 회사는 다음과 같은 업무를 수행합니다.</p>
      <p className="p1">1. 상품 등에 대한 정보 제공 및 구매 계약의 체결</p>
      <p className="p1">2. 기타 회사가 정하는 업무</p>
      <p className="p1">
        ② 회사는 상품 등의 품절 또는 기술적 사양의 변경 등의 경우에는 상품 등을
        변경할 수 있습니다.
      </p>
      <p className="p1">
        ③ 회사는 필요한 경우 상품 등의 변경 사실 및 그 사유를 회원에게 제 18조
        [회원에 대한 통지]에 정한 방법으로 통지합니다.
      </p>
      <p className="p1">
        ④ 제 2항의 경우 회사는 이로 인하여 회원이 입은 인과관계가 입증된 실제
        손해를 배상합니다. 다만, 회사가 고의 또는 과실이 없음을 입증하는
        경우에는 그러하지 아니합니다.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p1">제 8조 [서비스의 중단]</p>
      <p className="p1">
        ① 회사는 서비스 제공을 위한 정보통신설비의 보수점검, 교체 및 고장,
        통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로
        중단할 수 있습니다.
      </p>
      <p className="p1">
        ② 회사는 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여
        회원 또는 제3자가 입은 손해에 대하여 배상합니다. 다만 회사의 고의 또는
        과실이 없는 경우에는 그러하지 아니합니다.
      </p>
      <p className="p1">
        ③ 회사는 사업종목의 전환, 사업의 포기, 업체 간의 통합 등의 이 유로
        서비스를 제공할 수 없게 되는 경우 제 18조 [회원에 대한 통지]에 정 한
        방법으로 이용자에게 통지하고 당초 회사에서 제시한 조건에 따라 이용자에게
        보상합니다.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p1">제 9조 [구매신청]</p>
      <p className="p1">
        회원은 서비스에서 다음 또는 이와 유사한 방법에 의하여 구매를 신청하며,
        회사는 회원이 구매를 신청함에 있어서 다음의 각 내용을 알기 쉽게
        제공하여야 합니다.
      </p>
      <p className="p1">① 상품 등의 확인 및 선택</p>
      <p className="p1">② 결제방법의 선택 및 결제정보의 입력</p>
      <p className="p1">
        ③ 상품 등의 구매신청 및 이에 관한 확인 또는 회사의 확인에 대한 동의
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p1">제 10조 [계약의 성립]</p>
      <p className="p1">
        ① 회사는 제 9조 [구매신청]와 같은 구매 신청에 대하여 다음 각 호에
        해당하면 승낙하지 않을 수 있습니다.
      </p>
      <p className="p1">1. 신청 내용에 허위, 기재누락, 오기가 있는 경우</p>
      <p className="p1">
        2. 미성년자가 담배, 주류 등 청소년보호법에서 금지하는 상품 등을 구매하는
        경우
      </p>
      <p className="p1">
        3. 상행위(재판매)를 목적으로 구매하는 거래이거나, 거래 정황상
        상행위(재판매)를 목적으로 한 구매로 판단되는 경우
      </p>
      <p className="p1">
        4. 기타 회원의 귀책사유로 승인이 불가능하다고 판단되는 경우
      </p>
      <p className="p1">
        ② 회원의 구매계약 성립 시기는 회사가 구매완료를 구매절차 상에서 표시한
        시점으로 합니다.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p1">제 11조 [포인트 사용]</p>
      <p className="p1">
        ① 포인트 사용에 관한 상세한 사항은 회사가 정한 정책에 따르며, 회사는
        서비스를 통하여 이를 회원에게 안내합니다.
      </p>
      <p className="p1">
        ② 회원은 포인트를 통해서 상품 구매 등 달러 캐시의 서비스를 이용 할 수
        있습니다.
      </p>
      <p className="p1">
        ③ 회원이 이벤트 등을 통하여 회사로부터 포인트를 무상으로 제공받은 경우
        회사에서 정한 기한에 한해서만 이용이 가능하며, 서비스 내 상품 구매를
        통해 적립된 마일리지는 해당 구매가 취소될 경우 회사에 의해 환수될 수
        있습니다.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p1">제 12조 [수수료]</p>
      <p className="p1">
        ① 회사는 회원이 포인트를 통해서 상품 구매 서비스를 이용할 때
        회원으로부터 수수료를 받을 수 있습니다.
      </p>
      <p className="p1">
        ② 제1항에 따른 수수료는 회사가 사업적으로 필요하다고 판단되는 경우 해당
        수수료를 감면할 수 있습니다.
      </p>
      <p className="p1">
        ③ 회사의 정책에 따라 수수료는 사전 고지 없이 변경될 수 있습니다.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p1">제 13조 [포인트 소멸시효]</p>
      <p className="p1">① 다음 각 호의 경우 포인트가 소멸됩니다.</p>
      <p className="p1">
        1. 마지막 접속 날로부터 6개월이 경과하는 경우 모든 포인트는 소멸 됩니다.
      </p>
      <p className="p1">
        2. 탈퇴 후 1개월이 경과하는 경우 모든 포인트는 소멸 됩니다.
      </p>
      <p className="p1">
        3. 포인트 사용가능 조건 및 소멸에 대한 내용이 별도로 공지된 경우
      </p>
      <p className="p1">
        ② 회원이 부당 또는 부정하게 포인트를 취득한 경우 회원은 포인트를 사용할
        수 없으며 회사는 이를 회수할 수 있습니다.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p1">제 14조 [모바일교환권 상품의 구매, 취소, 환불 등]</p>
      <p className="p1">
        ① 회원은 절차를 거쳐 모바일교환권 상품을 구매하거나 선물할 수 있습니다.
      </p>
      <p className="p1">
        ② 모바일교환권의 수신자는 해당 모바일교환권을 수령한 날짜로부터 14일
        내에 거절할 수 있으며 수신자가 수령을 거절하는 경우 회사는 회원에게
        수신자의 거절 의사를 통보하고, 해당 모바일교환 권의 구매를 취소한 후
        회원에게 포인트로 자동 환불합니다.
        <span className="Apple-converted-space">&nbsp;</span>
      </p>
      <p className="p1">
        ③ 회원은 선물한 모바일 상품권에 대해 수신자가 거절을 아니하거나 사용
        전일 때 선물을 취소할 수 있습니다.
      </p>
      <p className="p1">
        ④ 모바일교환권의 교환 대상이 되는 상품 등이 일부 또는 전부의 교환처에서
        품절될 수 있으며, 구매 회원은 교환 신청 시 해당 내용을 안내 받은 경우
        회사에 환불 또는 주문 취소를 신청할 수 있습니다.
      </p>
      <p className="p1">
        ⑤ 회사는 환불권자에 대한 본인확인 절차 후 환불을 진행하는 것을 원칙으로
        합니다.
      </p>
      <p className="p1">
        ⑥ 회사는 회원이 구매 신청한 모바일교환권이 품절 등의 사유로 제공을 할 수
        없을 때에는 지체 없이 그 사유를 회원에게 통지하고 사전에 상품 등의
        대금을 받은 경우에는 대금을 받은 날부터 3영업일 이내에 환급하거나 환급에
        필요한 조치를 취합니다.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p1">제 15조 [배송 상품의 구매, 교환, 환불 등]</p>
      <p className="p1">
        ① 회원은 서명확인 절차를 거쳐 배송 상품을 구매하거나 선물할 수 있습니다.
      </p>
      <p className="p1">
        ② 회원은 전자상거래 등에서의 소비자보호에 관한 법률 제 17조에 따라
        수신자가 선물을 배송 받은 날로부터 7일 이내에 교환 또는 환불을 요청할 수
        있으며, 회사는 이에 따른 위약금이나 손해 배상을 청구하지 않습니다. 다만,
        회원의 단순 변심, 과오 등으로 청약철회 등을 하는 경우 재화 등의 반환에
        필요한 비용은 회원이 부담합니다.
      </p>
      <p className="p1">
        ③ 수신자가 귀책사유로 상품이 훼손된 경우에는 교환 또는 환불이
        불가능합니다.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p1">제 16조 [지급보증 등]</p>
      <p className="p1">
        모바일교환권의 지급보증 또는 피해보상보험계약은 모바일교환권에 기재된
        바에 따릅니다.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p1">제17조 [회사의 의무]</p>
      <p className="p1">
        ① 회사는 계속적이고 안정적인 서비스 제공을 위하여 관련 설비 등에 장애가
        발생할 때에는 부득이한 사유가 없는 한 지체 없이 이를 수리 또는
        복구합니다.
      </p>
      <p className="p1">
        ② 회사는 서비스 제공과 관련하여 취득한 회원의 개인정보를 본인의 승낙
        없이 제3자에게 일체 누설, 배포하지 않습니다. 다만, 관계 법령 또는 수사상
        목적에 따른 관련 기관의 요청 등 법률의 규정에 따른 적법한 절차에 의한
        경우에는 예외로 합니다.
      </p>
      <p className="p1">
        ③ 회사가 제공하는 서비스로 인하여 회원에게 손해가 발생한 경우, 그 손해가
        회사의 귀책사유로 인한 경우에 한하여 회사에서 책임을 부담합니다. 다만,
        이 경우 회사의 책임 범위는 통상손해에 한합니다.
      </p>
      <p className="p1">
        ④ 회사는 서비스제공자로서 관련 법령에서 정하는 기간 동안 필요 정보를
        보관합니다. 또한 보관중인 자료에 대하여 권리가 있는 자의 요청이 있는
        경우 관련 자료를 제공할 수 있습니다.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p1">제 18조 [회원에 대한 통지]</p>
      <p className="p1">
        ① 회사가 회원에게 통지를 하는 경우 서비스 화면 게시, SMS 발송, 기타
        통신수단 등을 이용할 수 있습니다.
      </p>
      <p className="p1">
        ② 회사는 불특정 회원에 대한 통지의 경우, 서비스 화면상에 7일 이상
        게시함으로써 개별 통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래와
        관련하여 중대한 영향을 미치는 사항에 대하여는 개별 통지를 할 수
        있습니다.
      </p>
      <p className="p1">
        ③ 회사는 회원의 연락처 미 기재, 변경 후 미 수정 등으로 인하여 개별
        통지가 어려운 경우에 한하여 제2항의 공지를 함으로써 개별 통지를 한
        것으로 간주합니다.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p1">제19조 [회원의 의무]</p>
      <p className="p1">
        ① 회원은 상품을 구매하기 전에 반드시 회사가 제공하는 상품 등의 상세
        내용과 거래의 조건을 정확하게 확인한 후 구매를 신청하여야합니다.
        구매하려는 상품 등의 상세내용과 거래의 조건을 확인하지 않고 구매하여
        발생하는 손해에 대하여 회원이 책임을 부담합니다.
      </p>
      <p className="p1">
        ② 회원은 본 약관 및 회사가 서비스와 관련하여 고지하는 내용을 준수하여야
        하며, 약관 및 고지내용을 위반하거나 이행하지 아니하여 발생하는 손해에
        대하여 책임을 부담합니다.
      </p>
      <p className="p1">
        ③ 회원은 회사가 서비스를 안전하게 제공할 수 있도록 회사에 협조하여야
        하며, 회사가 회원의 약관 위반행위를 발견하여 회원에게 해당위반행위에
        대하여 소명을 요청할 경우 회원은 회사의 요청에 적극 응하여야 합니다.
      </p>
      <p className="p1">
        ④ 미성년자인 회원이 서비스를 이용하여 상품 등을 구매 시 법정대리인이
        해당 계약에 대하여 동의를 하여야 정상적인 상품 등의 구매 계약이 체결될
        수 있습니다. 만약, 미성년자인 회원이 법정대리인의 동의 없이 상품을
        구매하는 경우 본인 또는 법정대리인이 이를 취소할 수 있습니다.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p1">제 20조 [회원의 금지의무]</p>
      <p className="p1">① 회원은 다음 각 호의 행위를 하여서는 안됩니다.</p>
      <p className="p1">
        1. 회사가 제공하는 서비스 이용방법에 의하지 아니하고 비정상적인 방법으로
        서비스를 이용하거나 시스템에 접근하는 행위
      </p>
      <p className="p1">
        2. 타인의 명의, 카드정보, 계좌정보 등을 도용하여 회사가 제공하는
        서비스를 이용하는 행위
      </p>
      <p className="p1">
        3. 회사가 정하지 않은 비정상적인 방법으로 포인트 또는 상품 등을
        취득하거나 사용하는 행위
      </p>
      <p className="p1">
        4. 회사가 정한 정보를 무단으로 변경하거나, 회사가 정한 정보 이외의
        정보(컴퓨터 프로그램 등)를 송신 또는 게시하는 행위
      </p>
      <p className="p1">
        5. 회사 및 기타 제3자의 저작권 등 지적재산권에 대한 침해행위
      </p>
      <p className="p1">
        6. 회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
      </p>
      <p className="p1">
        7. 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를
        서비스에 공개 또는 게시하는 행위
      </p>
      <p className="p1">
        8. 불법 할인의 목적으로 상품을 구매하여 제3자에게 판매하는 행위
      </p>
      <p className="p1">9. 구매의사 없이 반복적인 구매행위를 하는 행위</p>
      <p className="p1">10. 기타 불법적이거나 부당한 행위</p>
      <p className="p1">
        ② 회사는 회원이 본 조의 금지행위를 행하는 경우 서비스의 일부 또는 전부를
        이용정지하거나 서비스 이용계약을 임의로 해지할 수 있으며, 이 경우
        발생하는 손해에 대한 책임은 회원이 부담합니다. 회사는 필요한 경우 회원의
        금지행위 사실을 관련 정부기관 또는 사법기관에 통지할 수 있습니다.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p1">제 21조 [오류의 정정 등]</p>
      <p className="p1">
        ① 회원이 서비스를 이용함에 있어 오류가 있음을 안 때에는 회사에 대하여 그
        정정을 요구할 수 있습니다.
      </p>
      <p className="p1">
        ② 회사는 제1항의 규정에 따른 오류의 정정요구를 받은 때 또는 스스로
        전자금융거래에 오류가 있음을 안 때에는 이를 즉시 조사하여 처리한 후
        정정요구를 받은 날 또는 스스로 오류가 있음을 안 날부터 2주 이내에 그
        결과를 회원에게 통보합니다.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p1">제 22조 [저작권의 귀속 및 이용 제한]</p>
      <p className="p1">
        ① 회사가 작성한 저작물에 대한 저작권 기타 지적재산권은 회사에
        귀속합니다.
      </p>
      <p className="p1">
        ② 회원은 서비스를 이용함으로써 얻은 정보 중 회사에게 지적재산권이 귀속된
        정보를 회사의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에
        의하여 영리 목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.
      </p>
      <p className="p1">
        ③ 회원이 제2항을 위반하여 회사에 손해를 끼칠 경우 이에 대한 배상 책임을
        집니다.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p1">제 23조 [개인정보의 보호]</p>
      <p className="p1">
        ① 회사는 회원의 정보수집 시 구매계약 이행에 필요한 최소한의 정보를
        수집하고 &quot;정보통신망이용촉진및정보보호등에관한법률&quot; 및
        &quot;개인정보보호법&quot; 등 법령이 정하는 바에 따라 회원의 개인정보를
        보호하기 위해 노력합니다.
      </p>
      <p className="p1">
        ② 회사는 관련 법률이 정하는 바에 따라 회원의 개인정보를 보호하기 위해
        노력하며, 회원의 개인정보에 대해서는 관련 법령 및 회사의
        개인정보취급방침이 적용됩니다.
      </p>
      <p className="p1">
        ③ 회사가 회원의 개인정보를 수집 및 이용, 제3자 제공하는 경우에는 반드시
        회원의 동의를 받아야 하며, 해당 개인정보는 본인의 동의 없이 제3자에게
        제공하거나 수집 목적 이외에 이용할 수 없습니다.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p1">제 24조 [책임제한]</p>
      <p className="p1">
        ① 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할
        수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.
      </p>
      <p className="p1">
        ② 회사는 회원의 귀책사유로 인한 서비스 이용 장애에 대하여는 책임을 지지
        않습니다.
      </p>
      <p className="p1">
        ③ 회사는 단말기 자체의 결함이나 이동통신서비스의 장애 등으로 인하여
        서비스 이용이 불가한 경우 해당 통신사의 고객센터를 통하여 조치하여야
        하며, 이로 인하여 발생한 손해에 대하여 책임을 지지 아니합니다.
      </p>
      <p className="p1">
        ④ 회사는 회원 상호간, 회원과 가맹점, 회원과 광고주 사이 분쟁이 발생하는
        경우 개입하지 아니하며, 그로 인한 손해를 배상할 책임도 없습니다.
      </p>
      <p className="p1">
        ⑤ 회사는 회원이 개인정보를 타인에게 유출 또는 제공함으로써 발생하는
        피해나 회원의 과실 또는 부주의로 인하여 발생한 피해에 대하여 일체 책임을
        지지 아니합니다.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p1">제 25조 [분쟁해결]</p>
      <p className="p1">
        ① 회사는 회원이 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상
        처리하기 위하여 피해 보상 처리 기구인 고객만족센터를 설치, 운영합니다.
      </p>
      <p className="p1">
        ② 회사는 회원으로부터 제출되는 불만 사항 및 의견은 우선적으로 그 사항을
        처리합니다. 다만, 신속한 처리가 곤란한 경우에는 회원에게 그 사유와 처리
        일정을 즉시 통보해 드립니다.
      </p>
      <p className="p1">
        ③ 회사와 회원 간에 발생한 전자상거래 분쟁과 관련하여 회원의 피해 구제
        신청이 있는 경우에는 공정거래위원회 또는 시도지사가 의뢰하는
        분쟁조정기관의 조정에 따를 수 있습니다.
        <span className="Apple-converted-space">&nbsp;</span>
      </p>
      <p className="p1">
        ④ 회원이 모바일교환권 수신자를 잘못 지정하여 모바일교환권을
        선물하였음에도 불구하고 수신자가 해당 모바일교환권을 사용함으로써 회원과
        수신자 간 발생된 분쟁에 회사는 관여하지 않으며, 회원과 수신자가
        자체적으로 해결하여야 합니다.
      </p>
      <p className="p1">
        ⑤ 모바일교환권 수신자가 회사가 제공하지 않은 방법으로 제3자에게
        모바일교환권을 전송함으로써 수신자와 제3자 간에 모바일교환권 사용권한에
        관한 분쟁이 발생된 경우, 회사는 이에 관여하지 않으며 수신자와 해당
        제3자가 해결하여야 합니다.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p1">제 26조 [준거법 및 재판관할]</p>
      <p className="p1">
        ① 본 약관과 관련된 사항에 대하여는 대한민국 법을 준거법으로 합니다.
      </p>
      <p className="p1">
        ② 회사와 회원 간에 발생한 분쟁에 관한 소송은 민사소송법상의 관할법원에
        제기합니다.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p2">
        <br />
      </p>
    </>
  );
}

export default TermOfSevice;
