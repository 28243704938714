import { ReactElement } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Main from './components/Main';
import NotFound from './components/NotFound';
import Privacy from './components/Privacy';
import TermOfSevice from './components/TermOfSevice';
import Header from './components/Header';
import UserDelete from './components/UserDelete';

function App(): ReactElement {
  return (
    <div className="App">
      <Header />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/term-of-service" element={<TermOfSevice />} />
          <Route path="/user-delete" element={<UserDelete />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
