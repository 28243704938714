import { ReactElement } from 'react';
import './Privacy.css';

function Privacy(): ReactElement {
  return (
    <>
      <h1 className="my-4 text-4xl font-bold">
        달러 캐시 개인 정보 수집 및 이용 동의
      </h1>
      <p className="p1">[개인정보 수집 및 이용 동의]</p>
      <p className="p1">1. 수집하는 개인정보항목</p>
      <p className="p1">
        회사는 서비스 제공을 위해 필요한 최소한의 범위 내에서 개인정보를
        수집하고 있습니다.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p1">[필수항목]</p>
      <p className="p1">1) 회원가입 시 수집항목</p>
      <p className="p1">
        생년월일, 성별, 개인식별정보(카카오톡 고유 번호), 이메일 주소, 전화번호
      </p>
      <p className="p1">2) 상품 구매 시 수집항목</p>
      <p className="p1">전화번호, 이름, 네이버 아이디</p>
      <p className="p1">3) 서비스 이용과정 및 서비스 처리과정 시 수집항목</p>
      <p className="p1">
        서비스 이용기록, 접속 로그, 쿠키, 접속 IP 정보, 결제기록, MAC어드레스,
        운영체제 종류 및 버전 정보, 기기 정보
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p1">[선택항목]</p>
      <p className="p1">1) 마케팅 활용 수신 동의</p>
      <p className="p1">휴대폰번호</p>
      <p className="p1">2) 이벤트, 경품 당첨 안내 및 배송지 확인</p>
      <p className="p1">주소</p>
      <p className="p2">
        <br />
      </p>
      <p className="p1">2. 개인정보 수집 목적</p>
      <p className="p1">회사는 다음과 같은 이유로 개인정보를 수집합니다.</p>
      <p className="p1">
        1) 관련 서비스 제공, 구매, 모바일교환권 및 선불형지급수단 발송 / 결제 /
        취소 / 교환 / 전환 / 환불 / 수신 알림 등의 서비스
      </p>
      <p className="p1">
        2) 회원제 서비스 이용에 따른 본인확인, 개인 식별, 불량회원 또는
        이용약관을 위반하는 회원 등의 부정 이용 방지와 비인가 사용 방지, 가입
        의사 확인, 연령확인, 불만처리 등 민원처리, 고지사항 전달
      </p>
      <p className="p1">
        3) 신규 서비스 개발 및 맞춤 서비스 제공, 이벤트 등 광고성 정보 전달,
        인구통계학적 특성에 따른 서비스 제공 및 광고 게재, 접속 빈도 파악 또는
        회원의 서비스 이용에 대한 통계
      </p>
      <p className="p1">4) 오퍼월 서비스 회사에 개인 식별 정보 제공</p>
      <p className="p2">
        <br />
      </p>
      <p className="p1">3. 개인정보보유기간</p>
      <p className="p1">
        정보주체 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면
        지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한
        기간 동안 보존합니다.
      </p>
      <p className="p1">1) 회사 내부 방침에 의한 정보보유 사유</p>
      <p className="p1">- 보유기간 : 1년</p>
      <p className="p1">- 보유 이유 : 부정 이용 방지</p>
      <p className="p1">2) 전자금융거래에 관한 기록</p>
      <p className="p1">
        - 보유 기간 : 5년(단, 건별 거래금액 1만원 미만은 1년)
      </p>
      <p className="p1">- 보유 이유 : 전자금융거래법</p>
      <p className="p1">3) 계약 또는 청약철회 등에 관한 기록</p>
      <p className="p1">- 보유 기간 : 5년</p>
      <p className="p1">
        - 보유 이유 : 전자상거래 등에서의 소비자보호에 관한 법률
      </p>
      <p className="p1">4) 소비자의 불만 또는 분쟁처리에 관한 기록</p>
      <p className="p1">- 보유 기간 : 3년</p>
      <p className="p1">
        - 보유 이유 : 전자상거래 등에서의 소비자보호에 관한 법률
      </p>
      <p className="p1">5) 본인확인에 관한 기록</p>
      <p className="p1">- 보유 기간 : 6개월</p>
      <p className="p1">
        - 보유 이유 : 정보통신망 이용촉진 및 정보보호 등에 관한 법률
      </p>
      <p className="p1">6) 웹 사이트 방문기록</p>
      <p className="p1">- 보유 기간 : 3개월</p>
      <p className="p1">- 보유 이유 : 통신비밀보호법</p>
      <p className="p2">
        <br />
      </p>
      <p className="p1">4. 개인정보 수집 동의 거부 권리</p>
      <p className="p1">
        정보주체께서는<span className="s1">&nbsp;</span>개인정보
        <span className="s1">&nbsp;</span>수집<span className="s1">&nbsp;</span>
        동의에
        <span className="s1">&nbsp;</span>대한<span className="s1">&nbsp;</span>
        거부
        <span className="s1">&nbsp;</span>권리가
        <span className="s1">&nbsp;</span>있으며
        <span className="s1">,&nbsp;</span>미동의
        <span className="s1">&nbsp;</span>시<span className="s1">&nbsp;</span>
        회원가입<span className="s1">&nbsp;</span>및
        <span className="s1">&nbsp;</span>서비스
        <span className="s1">&nbsp;</span>제공에
        <span className="s1">&nbsp;</span>제약이
        <span className="s1">&nbsp;</span>있을
        <span className="s1">&nbsp;</span>수<span className="s1">&nbsp;</span>
        있고
        <span className="s1">,&nbsp;</span>미동의
        <span className="s1">&nbsp;</span>하신
        <span className="s1">&nbsp;</span>경우<span className="s1">&nbsp;</span>
        정보가
        <span className="s1">&nbsp;</span>제공되지
        <span className="s1">&nbsp;</span>
        않습니다<span className="s1">.</span>
      </p>
    </>
  );
}

export default Privacy;
