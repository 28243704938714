import axios from 'axios';
import { ReactElement, useEffect, useState } from 'react';
import qs from 'qs';
import CryptoJS from 'crypto-js';

const saltKey =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJrYWthby0xMTIzNCIsImlhdCI6MTY3MzY5ODY3NCwiZXhwIjoxNjczNzQxODc0fQ.gPIl6_5WrX4xg1nC69-G4sb7k4p_iBekY68dXkNe1Wk';

interface User {
  nickName: string;
  phoneNumber: string;
  dollar: number;
  point: number;
}

const restApiKey = 'cc719db73ce8e53b4f45c414d2eaf1c7';
const redirectUri = `${window.location.protocol}//${window.location.host}/user-delete`;

const SocialKakaoLogin = (): ReactElement => {
  const kakaoURL = `https://kauth.kakao.com/oauth/authorize?client_id=${restApiKey}&redirect_uri=${redirectUri}&response_type=code`;
  const handleLogin = (): void => {
    window.location.href = kakaoURL;
  };

  return (
    <div className="flex items-center justify-center">
      <button
        className="w-[12rem] rounded-xl bg-[#ffe812] p-4 text-lg font-bold"
        onClick={handleLogin}
      >
        카카오 계정 로그인
      </button>
    </div>
  );
};

const SocialKakaoLogOut = (): ReactElement => {
  const kakaoURL = `https://kauth.kakao.com/oauth/logout?client_id=${restApiKey}&logout_redirect_uri=${redirectUri}`;
  const handleLoginOut = (): void => {
    window.location.href = kakaoURL;
  };

  return (
    <div className="flex items-center justify-center">
      <button
        className="w-[12rem] rounded-xl bg-[#ffe812] p-4 text-lg font-bold"
        onClick={handleLoginOut}
      >
        카카오 계정 로그아웃
      </button>
    </div>
  );
};

function UserInfo({
  user,
  code,
  accessToken,
  kakaoAccessToken,
  isDeleted,
}: {
  user: User | null;
  code: string | null;
  accessToken: string;
  kakaoAccessToken: string;
  isDeleted: boolean;
}): ReactElement {
  const [isShowButton, setIsShowButton] = useState(false);
  if (isDeleted) {
    return (
      <div className="w-full p-4 text-center text-2xl font-bold text-red-500">
        이미 계정이 삭제 되었습니다.
      </div>
    );
  }

  if (code && user === null) {
    return (
      <div className="w-full p-4 text-center text-2xl font-bold">
        계정 데이터 로딩 중입니다.
      </div>
    );
  }

  if (code && user && user.nickName === '') {
    return (
      <div className="w-full p-4 text-center text-2xl font-bold text-red-500">
        계정이 존재 하지 않습니다.
      </div>
    );
  }

  const deleteData = async (): Promise<void> => {
    const isSuccess = await axios
      .delete('https://api.dollar-cash.moneymoa.net/auth/soft-delete', {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then(() => true)
      .catch(() => null);

    if (!isSuccess) {
      return;
    }

    await axios
      .get('https://kapi.kakao.com/v1/user/unlink', {
        headers: { Authorization: `Bearer ${kakaoAccessToken}` },
      })
      .then((res) => {
        alert('계정이 삭제 되었습니다.');
        window.location.replace('/user-delete');
        console.log(res.data);
      })
      .catch(() => null);
  };

  if (user) {
    return (
      <div className="flex flex-col items-center justify-center gap-2 text-lg font-semibold">
        <div className="text-2xl font-bold">계정 정보</div>
        <div>닉네임 : {user.nickName}</div>
        <div>휴대폰 번호 : {user.phoneNumber}</div>
        <div>보유 포인트 : {user.point}</div>
        <div>보유 달러 : ${Number(user.dollar).toFixed(2)}</div>
        <div className="p-2"></div>
        {isShowButton ? (
          <div className="flex flex-col items-center justify-center gap-4 py-4">
            <div className="whitespace-pre-line text-2xl font-bold">
              계정을 정말 삭제 하겠습니까?
            </div>
            <div>
              계정 삭제 후, 30일간 정보가 유지가 됩니다. 30일 이후에는 모든
              정보가 삭제 됩니다.
            </div>
          </div>
        ) : (
          <button
            className="w-[12rem] rounded-xl bg-[#ff5252] p-4 text-lg font-bold text-white"
            onClick={(): void => {
              setIsShowButton(true);
            }}
          >
            계정 삭제
          </button>
        )}
        {isShowButton && (
          <div className="flex flex-row gap-4">
            <button
              className="w-[5.5rem] rounded-xl bg-[#ff5252] p-4 text-lg font-bold text-white"
              onClick={deleteData}
            >
              예
            </button>
            <button
              className="w-[5.5rem] rounded-xl bg-[#DFDFDF] p-4 text-lg font-bold text-black"
              onClick={(): void => {
                setIsShowButton(false);
              }}
            >
              아니요
            </button>
          </div>
        )}
      </div>
    );
  }
  return <></>;
}

function UserDelete(): ReactElement {
  const code = new URL(window.location.href).searchParams.get('code');

  const [user, setUser] = useState<User | null>(null);

  const [kakaoAccessToken, setKakaoAccessToken] = useState('');

  const [accessToken, setAccessToken] = useState('');

  const [isDeleted, setIsDeleted] = useState(false);

  const getUser = async (): Promise<void> => {
    if (!code) {
      return;
    }

    const tempKakaoAccessToken = await axios
      .post(
        'https://kauth.kakao.com/oauth/token',
        qs.stringify({
          grant_type: 'authorization_code',
          client_id: restApiKey,
          redirect_uri: redirectUri,
          code,
        })
      )
      .then((res) => {
        setKakaoAccessToken(res.data.access_token);
        return res.data.access_token;
      })
      .catch(() => null);

    if (tempKakaoAccessToken === '') {
      console.log('failed to get kakao access token', code);
      return;
    }

    const userId = await axios
      .get('https://kapi.kakao.com/v2/user/me', {
        headers: { Authorization: `Bearer ${tempKakaoAccessToken}` },
      })
      .then((res) => `kakao-${res.data.id}`)
      .catch(() => null);

    if (!userId) {
      console.log('failed to get userId');
      return;
    }

    const isUserExisted = await axios
      .get(
        `https://admin-api.dollar-cash.moneymoa.net/auth/isDeleted/${userId}`
      )
      .then((res) => {
        setIsDeleted(res.data.isDeleted);
        if (!res.data.isExisted) {
          setUser({
            nickName: '',
            phoneNumber: '',
            point: 0,
            dollar: 0,
          } as User);
        }

        return res.data.isExisted && !res.data.isDeleted;
      })
      .catch(() => null);

    if (!isUserExisted) {
      console.log('user not existed');
      return;
    }

    const tempAccessToken = await axios
      .post('https://api.dollar-cash.moneymoa.net/auth/signIn', {
        secret: CryptoJS.enc.Hex.stringify(
          CryptoJS.SHA256(`${userId}${saltKey}`)
        ),
        userId,
      })
      .then((res) => {
        setAccessToken(res.data.accessToken);
        return res.data.accessToken;
      })
      .catch(() => null);

    if (tempAccessToken === '') {
      console.log('failed to get access token');
      return;
    }

    await axios
      .get('https://api.dollar-cash.moneymoa.net/auth/info', {
        headers: { Authorization: `Bearer ${tempAccessToken}` },
      })
      .then((res) => {
        setUser(res.data as User);
      })
      .catch(() => {
        setUser({ nickName: '', phoneNumber: '', point: 0, dollar: 0 } as User);
      });
  };

  useEffect(() => {
    if (code) {
      getUser();
    }
  }, [code]);

  return (
    <div className="flex flex-col gap-4 p-4">
      <UserInfo
        user={user}
        code={code}
        kakaoAccessToken={kakaoAccessToken}
        accessToken={accessToken}
        isDeleted={isDeleted}
      />
      {code ? <SocialKakaoLogOut /> : <SocialKakaoLogin />}
    </div>
  );
}
export default UserDelete;
