import { ReactElement, useEffect } from 'react';

function NotFound(): ReactElement {
  useEffect(() => {
    window.location.replace('/');
  }, []);
  return <div />;
}

export default NotFound;
