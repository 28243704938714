import { ReactElement } from 'react';
import logo from '../asset/logo.png';

function Header(): ReactElement {
  return (
    <div className="justify-left flex items-center gap-6 bg-[#66bb6a]">
      <img
        className="h-[4rem]"
        src={logo}
        onClick={(): void => window.location.replace('/')}
      />
      <div
        className="text-lg font-semibold text-white"
        onClick={(): void => window.location.replace('/')}
      >
        홈
      </div>
      <div
        className="text-lg font-semibold text-white"
        onClick={(): void => window.location.replace('/user-delete')}
      >
        계정 삭제
      </div>
    </div>
  );
}

export default Header;
