import { ReactElement } from 'react';
import appInfo from '../asset/app-info.png';
import playstore from '../asset/playstore.png';

function Main(): ReactElement {
  return (
    <div>
      <div className="m-5 flex flex-col gap-5">
        <a href="https://play.google.com/store/apps/details?id=com.moneymoa.dollarcash">
          <img className="mx-auto w-[20rem]" src={appInfo}></img>
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.moneymoa.dollarcash">
          <img className="mx-auto w-[10rem]" src={playstore}></img>
        </a>
      </div>
    </div>
  );
}

export default Main;
